import React, { Component } from 'react';
import { connect } from 'react-redux';
import SweetAlert from "sweetalert2-react";
import Select from "react-select";

import * as actions from '../actions';
import { getConfig } from '../config';

import icons from './icons/payment-methods';

import checkBlue from '../assets/images/check_blue.png';
import ssl from '../assets/images/security/ssl.png';
import aws from '../assets/images/security/aws.png';
import dataImg from '../assets/images/security/data.png';
import checkGreen from '../assets/images/check_green.png';
import checkGrey from '../assets/images/check_grey.png';
import hostedLoader from '../assets/images/hosted/loader.gif';

const queryString = require('query-string');

class CollectionPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputs: [],
      data: {},
      isTokenError: false,
      showError: false,
      errorMessage: "",
      isLoadingInputs: false,
      css: ""
    };
  }

  componentDidMount() {
    this.props.showUiParts(false);

    const data = queryString.parse(window.location.search);

    getConfig().then(
      (config) => {
        this.setState({
          css: config.css,
          data
        });
      }
    );

    if (data.midId && data.token) {
      this.setState({
        isLoadingInputs: true
      });
      this.getInputs(data.midId, data.token).then(
        (response) => {
          if (response && response.length) {
            for (let i = 0; i < response.length; ++i) {
              if (!response[i].fieldValue) {
                response[i].fieldValue = "";
              }
            }
          }
          this.setState({
            inputs: response,
            isLoadingInputs: false
          });
        }
      );
    }
  };

  getInputs = async (midId, token) => {
    return this.props.fetchHostedInputs(midId, token).then(
      res => {
        if (res.err) {
          if (res.status === 401) {
            window.location.href = "/no-access";
          } else {
            if (res.err === "token_error") {
              this.setState({
                isTokenError: true
              });
            } else {
              this.setState({
                showError: true,
                errorMessage: res.err
              });
            }
          }
          return;
        }

        return res;
      }
    );
  };

  onValueChange = (event, inputIndex) => {
    const { inputs } = this.state;

    if (inputs[inputIndex].fieldType === "INPUT_FIELD") {
      inputs[inputIndex].fieldValue = event.target.value;
    } else {
      inputs[inputIndex].fieldValue = event && event.value ? event.value : "";
    }

    this.setState({
      inputs
    });
  };

  validateInput = (input) => {
    if (input) {
      if (input.fieldType === "INPUT_FIELD" ) {
        if (input.pattern) {
          const reg = new RegExp(input.pattern);
          return reg.test(input.fieldValue);
        }
        if (input.maxLength && (input.fieldValue.length > input.maxLength)) {
          return false;
        }
        if (input.minLength && (input.fieldValue.length < input.minLength)) {
          return false;
        }
        if (input.fieldValue.length === 0 && input.required) {
          return false;
        }
        return true;
      }

      if (input.fieldType === "DROPDOWN") {
        return input.fieldValue.length > 0;
      }
    }
    
    return false;
  };

  isFormValid = () => {
    const { inputs } = this.state;

    if (inputs && inputs.length) {
      for (let i = 0; i < inputs.length; ++i) {
        if (!this.validateInput(inputs[i])) {
          return false;
        }
      }
    }

    return true;
  };

  onSubmit = async () => {
    const { data, inputs } = this.state;
    if (this.isFormValid()) {
      for (let i = 0; i < inputs.length; ++i) {
        data[inputs[i].fieldName] = inputs[i].fieldValue;
      }
      this.props.submitHostedInputs(data)
        .then(response => {
          if (response && response.paymentUrl) {
            const url = response.paymentUrl.replace(' ','');
            window.location.href = url;
          }
          if (response.err) {
            this.setState({
              showError: true,
              errorMessage: response.err
            });
          }
        });
    }
  };

  onCancel = () => {
    const { data } = this.state;
    if (data.returnUrl) {
      window.location.href = data.returnUrl;
    }
  };

  render() {
    const {
      data,
      errorMessage,
      inputs,
      isLoadingInputs,
      isTokenError,
      showError
    } = this.state;

    return (
      <>
        {isTokenError ?
          (
            <div className="message">
              <h3>Transaction Token Expired</h3>
              <span>Sorry, the transaction has already been initialized and cannot be altered anymore.</span>
            </div>
          ) : (
            <main className='main-container'>
              <div className="header">
                <div className="logo">
                  <img src={ icons[data.method] } alt="logo" />
                </div>
                <h1 className="title">
                  { data.method && data.method.replace(/_/g, " ") }
                </h1>
              </div>
              <div className='inner-container'>
                <div className="main">
                  <div className="content-wrapper">
                    
                    <div className="steps">
                      <div className="step">
                        <img src={ checkBlue } alt="checmark" />
                        Cashier
                      </div>
                      <div className="step active">
                        <span className="number">2</span>
                        <span>Verification</span>
                      </div>
                      <div className="step">
                        <span className="number">3</span>
                        <span>Payment</span>
                      </div>
                    </div>

                    <div className="sidebar">
                      <div className="logo">
                        <img src={ icons[data.method] } alt="logo" />
                      </div>
                      <h2 className="title">Order</h2>
                      <div className="row">
                        <div className="item">
                          <span>Amount:</span> { data.amount || "0.00" }
                        </div>
                        <div className="item">
                          <span>Currency:</span> { data.currency }
                        </div>
                      </div>
                      <div className="item ticket-id">
                        <span>Ticket ID:</span> { data.transactionId }
                      </div>
                    </div>

                    <div className="form">
                      <div className="inputs">
                        {isLoadingInputs ? (
                          <img src={ hostedLoader } alt="loader" height="70" />
                        ) : (
                          <>
                            {inputs && inputs.length > 0 && inputs.map((input, index) =>
                              <div key={ input.fieldName } className="input-column">
                                <label>
                                  { input.fieldLabel }
                                  { input.fieldDescription && (
                                    <span>
                                      { input.fieldDescription }
                                    </span>
                                  )}
                                </label>
                                {input.fieldType === "INPUT_FIELD" ? (
                                  <>
                                    <input
                                      type={ input.contentType }
                                      value={ input.fieldValue }
                                      className={ `form-control ${this.validateInput(input) && 'valid'}` }
                                      placeholder={ `Fill in ${input.fieldLabel}` }
                                      onChange={ (e) => this.onValueChange(e, index) }
                                    />
                                    <img
                                      src={ this.validateInput(input) ? checkGreen : checkGrey }
                                      alt={ this.validateInput(input) ? 'valid' : 'invalid' }
                                      className="check"
                                    />
                                  </>
                                ) : (
                                  <Select
                                    className={ `select-container ${this.validateInput(input) && 'valid'}` }
                                    isClearable={ true }
                                    options={ input.dropdownValues }
                                    value={ input.dropdownValues.find(e => e.value === input.fieldValue) }
                                    onChange={ (value) => this.onValueChange(value, index) }
                                  />
                                )}
                              </div>
                            )}
                          </>
                        )}
                            
                      </div>
                      <div className="actions">
                        <button
                          className="btn btn-cancel"
                          onClick={ () => this.onCancel() }
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-submit"
                          disabled={ !this.isFormValid() }
                          onClick={ () => this.onSubmit() }
                        >
                          Submit
                        </button>
                      </div>
                      <div className="safety-icons">
                        <img src={ ssl } alt="secure ssl encryption" />
                        <img src={ aws } alt="aws" />
                        <img src={ dataImg } alt="secure data" />
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <SweetAlert
                show={ showError }
                title={ "ERROR" }
                type="error"
                confirmButtonColor={ "#187EED" }
                confirmButtonText={ "OK" }
                text={ errorMessage }
                onConfirm={ this.onConfirm }
              />

            </main>
          )}
      </>  
    );
  }
}

export default connect(null, actions)(CollectionPage);
