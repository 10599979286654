import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Loader from './Loader';

import CollectionPage from './CollectionPage';
import TestProvider from './TestProvider';

import '../assets/css/globalPayin/index.scss';

import { connect } from 'react-redux';
import * as actions from '../actions';

import { getConfig } from '../config';

const font = {
  eng: "'Lato', sans-serif",
  zho: "'Alibaba-PuHuiTi-Regular', regular",
  hin: "'Lato', sans-serif",
  may: "'Lato', sans-serif",
  ind: "'Lato', sans-serif",
  vie: "'Lato', sans-serif",
  tha: "'Lato', sans-serif"
};

class App extends Component {
  constructor() {
    super();
    this.state = {
      css: "",
      userLoggedIn: !!sessionStorage.getItem('cnyTokenUserAuthJWT')
    };
  }

  async componentDidMount() {
    const config = await getConfig();
    const css = config.css;

    this.setState({
      css: css
    });
  };

  render() {
    const { css } = this.state;

    return (
      <div className={ css } style={ { fontFamily: font[this.props.lang], width: '100%' } }>
        <BrowserRouter>
          <Route>
            <Loader />
            <Route exact path="/global-pay" component={ CollectionPage } />
            <Route exact path="/test-provider/:id" component={ TestProvider } />
          </Route>
        </BrowserRouter>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.language || 'eng',
    showParts: state.showParts || false,
  };
}

export default connect(mapStateToProps, actions)(App);
