import React, { Component } from 'react';
import { connect } from 'react-redux';
import SweetAlert from "sweetalert2-react";

import * as actions from '../actions';
import { getConfig } from '../config';

import Loader from '../assets/images/hosted/loader.gif';

class TestProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statuses: [],
      data: {},
      showError: false,
      errorMessage: "",
      isLoadingStatuses: false,
      css: "",
      newAmount: ""
    };
  }

  componentDidMount() {
    this.props.showUiParts(false);

    const id = this.props.match.params.id;

    getConfig().then(
      (config) => {
        this.setState({
          css: config.css
        });
      }
    );

    if (id) {
      this.setState({
        isLoadingStatuses: true
      });

      this.getStatuses(id).then(
        (response) => {
          if (response) {
            this.setState({
              statuses: response.futureStatuses,
              data: {
                id: response.id,
                status: response.status,
                amount: response.amount,
                orderId: response.orderId,
                method: response.method,
                country: response.country,
                currency: response.currency,
                timestamp: response.timestamp,
                redirectUrl: response.redirectUrl
              },
              newAmount: response.amount,
              isLoadingStatuses: false
            });
          }
        }
      );
    }
  };

  getStatuses = async (id, status, amount) => {
    return this.props.fetchStatuses(id, status, amount).then(
      res => {
        if (res.err) {
          if (res.status === 401) {
            window.location.href = "/no-access";
          } else {
            this.setState({
              showError: true,
              errorMessage: res.err
            });
          }
          
          return;
        }

        return res;
      }
    );
  };

  getStatusDescription = (status) => {
    let description = "";
    switch (status) {
    case "PENDING":
      description = "This status is the immediate status of the transaction when the transaction is set to the merchant. PENDING status can be changed to FAIL or SUCCESS.";
      break;
    case "REJECTED":
      description = "This status can actually be an immediate response which is sent instead of pending if the payment fails validation tests.";
      break;
    case "FAIL":
      description = "This is the final status.";
      break;
    case "SUCCESS":
      description = "Success is a status that can be later changed to reversed. Payment amount and currency can change during a success callback.";
      break;
    case "REVERSED":
      description = "The transaction has been reversed (failed). This is a final status.";
      break;
    default:
      description = "This is the final status.";
      break;
    }

    return description;
  };

  onValueChange = (value, inputId) => {
    if (!isNaN(value) || value.match(/^[-+]?(\d+\.?|\d*\.\d+)$/)) {
      this.setState({
        [inputId]: value || ""
      });
    }
  };

  onSendCallback = async (url, amount, orderId) => {
    return this.props.sendCallback(url, amount, orderId).then(
      res => {
        if (res.err) {
          if (res.status === 401) {
            window.location.href = "/no-access";
          } else {
            this.setState({
              showError: true,
              errorMessage: res.err
            });
          }
          
          return;
        }

        return res;
      }
    );
  };

  onButtonClick = (status) => {
    const { newAmount, data, statuses } = this.state;
    const url = statuses[status];

    if (status && url) {
      this.setState({
        isLoadingStatuses: true
      });
      this.onSendCallback(url, status === "SUCCESS" ? newAmount : data.amount, data.orderId).then(
        (response) => {
          if (response) {
            this.setState({
              statuses: response.futureStatuses,
              data: Object.assign(data, {
                status: response.status,
                amount: response.amount,
                timestamp: response.timestamp,
                id: response.id
              }),
              isLoadingStatuses: false
            });
          }
        });
    } else {
      window.location.href = data.redirectUrl.indexOf("http") > -1 ? data.redirectUrl : `https://${data.redirectUrl}`;
    }
  };

  render() {
    const {
      newAmount,
      data,
      errorMessage,
      isLoadingStatuses,
      showError
    } = this.state;

    return (
      <div className="test-provider-page">
        <main className="container">
          <div className="row">
            <h2 className="title">
              This is a simulated payment page
            </h2>
          </div>
          <div className="row main-row order-details">
            <h2>Order details</h2>
            <div className="info">
              <table>
                <tbody>
                  <tr>
                    <td className="item"><span>Timestamp:</span> &nbsp; { data.timestamp }</td>
                    <td className="desktop-only"></td>
                    <td className="item"><span>ID:</span> &nbsp; { data.id }</td>
                  </tr>
                  <tr>
                    <td className="item"><span>Order ID:</span> &nbsp; { data.orderId }</td>
                    <td className="desktop-only"></td>
                    <td className="item"><span>Method:</span> &nbsp; { data.method }</td>
                  </tr>
                  <tr>
                    <td className="item"><span>Country:</span> &nbsp; { data.country }</td>
                    <td className="desktop-only"></td>
                    <td className="item"><span>Status:</span> &nbsp; { data.status }</td>
                  </tr>
                  <tr>
                    <td className="item"><span>Currency:</span> &nbsp; { data.currency }</td>
                    <td className="desktop-only"></td>
                    <td className="item"><span>Amount:</span> &nbsp; { data.amount }</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="row main-row controls">
            { isLoadingStatuses ? (
              <img src={ Loader } alt="loader" height="70" />
            ) : (
              <>
                <div className="status-row">
                  <div className="text">
                    <h3>
                      Current transaction status: { data.status }
                    </h3>
                    <div className="description">
                      { this.getStatusDescription(data.status) }
                    </div>
                  </div>
                </div>
                { data.status === "PENDING" && (
                  <div className="status-row">
                    <div className="text">
                      <h3>
                        Rejected
                      </h3>
                      <div className="description">
                        Rejected status is an immediate response which is sent instead of pending if the payment fails validation tests. Rejected response can be tested by sending a <b>rejected@test.com</b> as an email address.
                      </div>
                    </div>
                  </div>
                )}
                { data.status === "PENDING" && (
                  <div className="status-row">
                    <div className="text">
                      <h3>
                        Fail
                      </h3>
                      <div className="description">
                        { this.getStatusDescription("FAIL") }
                      </div>
                    </div>
                    <div className="actions">
                      <button
                        className="btn btn-submit"
                        onClick={ () => this.onButtonClick("FAIL") }
                      >
                        Send FAIL callback
                      </button>
                    </div>
                  </div>
                )}
                { data.status === "PENDING" && (
                  <div className="status-row">
                    <div className="text">
                      <h3>
                        Success
                      </h3>
                      <div className="description">
                        { this.getStatusDescription("SUCCESS") }
                      </div>
                    </div>
                    <div className="actions">
                      <input
                        type="text"
                        value={ newAmount }
                        className={ newAmount && !isNaN(newAmount) ? "valid" : "" }
                        onChange={ (e) => this.onValueChange(e.target.value, "newAmount") }
                      />
                      <button
                        className="btn btn-submit"
                        disabled={ !newAmount || isNaN(newAmount) }
                        onClick={ () => this.onButtonClick("SUCCESS") }
                      >
                        Send SUCCESS callback
                      </button>
                    </div>
                  </div>
                )}

                { data.status === "SUCCESS" && (
                  <div className="status-row">
                    <div className="text">
                      <h3>
                        Reversed
                      </h3>
                      <div className="description">
                        { this.getStatusDescription("REVERSED") }
                      </div>
                    </div>
                    <div className="actions">
                      <button
                        className="btn btn-submit"
                        onClick={ () => this.onButtonClick("REVERSED") }
                      >
                        Send REVERSED callback
                      </button>
                    </div>
                  </div>
                )}

                { data.status !== "PENDING" && (
                  <div className="status-row">
                    <div className="text">
                      <h3>
                        Complete payment
                      </h3>
                      <div className="description">
                        You will be redirected to the Redirect URL.
                      </div>
                    </div>
                    <div className="actions">
                      <button
                        className="btn btn-submit btn-submit-green"
                        onClick={ () => this.onButtonClick() }
                      >
                        Complete
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

        </main>

        <SweetAlert
          show={ showError }
          title={ "ERROR" }
          type="error"
          confirmButtonColor={ "#187EED" }
          confirmButtonText={ "OK" }
          text={ errorMessage }
          onConfirm={ this.onConfirm }
        /> 
      </div>
    );
  }
}

export default connect(null, actions)(TestProvider);
