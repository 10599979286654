import React, { Component } from 'react';

import { Column } from 'simple-flexbox';
import language from '../assets/lang/language';

import designImages from './icons/designs-images';

import { connect } from 'react-redux';
import * as actions from '../actions';
import { getConfig } from '../config';

let timeout = null;

class Loader extends Component {
  state = {
    loaded: false,
    css: ""
  }

  componentDidMount() {
    getConfig().then(
      (config) => {
        this.setState({
          css: config.css
        });
      }
    );
  }

  setLoader = () => {
    this.setState({loaded: true});
    clearTimeout(timeout);
  }

  render() {
    const { css } = this.state;
    const loader = language[this.props.lang].loader;

    timeout = setTimeout(() => {
      this.setLoader();
    }, 1000);

    if (this.state.loaded) {
      return (null);
    }

    return (
      <Column flexGrow={ 1 } vertical="center" horizontal="center" className="loadingScreen">
        <img src={ designImages[css] && designImages[css].loader } alt="loader" height="70" />
        <div className='loader-text'> {loader.loading} </div>
      </Column>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.language || 'eng',
  };
}

export default connect(mapStateToProps, actions)(Loader);
