import axios from 'axios';
import {
  SHOW_UI_PARTS
} from './types';
import { getConfig } from '../config';

export const showUiParts = (state) => dispatch => {
  dispatch({ type: SHOW_UI_PARTS, payload: state });
};

export const fetchHostedInputs = (midId, token) => async () => {
  try {
    const config = await getConfig();
    const res = await axios.get(`/api/v1/external/payment/hosted/fields?midId=${midId}&token=${token}`, {
      headers: {
        apikey: config.apikey
      }
    });

    return res.data;
  } catch (e) {
    if (e.response && e.response.data) {
      return {
        err: e.response.data.message,
        status: e.response.status
      };
    }
  }
};

export const submitHostedInputs = (data) => async () => {
  try {
    const config = await getConfig();
    const res = await axios.post('/api/v1/external/payment/hosted/frontend', data, {
      headers: {
        apikey: config.apikey
      }
    });

    return res.data;
  } catch (e) {
    if (e.response && e.response.data) {
      return {
        err: e.response.data.message,
        status: e.response.status
      };
    }
  }
};

export const fetchStatuses = (id, orderId, status, amount) => async () => {
  try {
    const config = await getConfig();
    let url = `/api/v1/external/payment/test/${id}`;
    if (orderId) {
      url += `?orderId=${orderId}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    if (amount) {
      url += `&amount=${amount}`;
    }
    const res = await axios.get(url, {
      headers: {
        apikey: config.apikey
      }
    });

    return res.data;
  } catch (e) {
    if (e.response && e.response.data) {
      return {
        err: e.response.data.message,
        status: e.response.status
      };
    }
  }
};

export const sendCallback = (url, amount, orderId) => async () => {
  try {
    const config = await getConfig();
    const res = await axios.get(`${url}&amount=${amount}&orderId=${encodeURIComponent(orderId)}`, {
      headers: {
        apikey: config.apikey
      }
    });

    return res.data;
  } catch (e) {
    if (e.response && e.response.data) {
      return {
        err: e.response.data.message,
        status: e.response.status
      };
    }
  }
};
