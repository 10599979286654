import BANKPAY from '../../assets/images/payment-methods/BANKPAY.png';
import QUICKPAY from '../../assets/images/payment-methods/QUICKPAY.png';
import UNIONPAY_INTERNATIONAL from '../../assets/images/payment-methods/UNIONPAY_INTERNATIONAL.png';
import UNIONPAY_INTERNATIONAL_EURO from '../../assets/images/payment-methods/UNIONPAY_INTERNATIONAL.png';
import UNIONPAY from '../../assets/images/payment-methods/UNIONPAY.png';
import ALIPAY from '../../assets/images/payment-methods/ALIPAY.png';
import CREDIT_CARD from '../../assets/images/payment-methods/CREDIT_CARD.png';
import COD from '../../assets/images/payment-methods/COD.png';
import UPI from '../../assets/images/payment-methods/UPI.png';
import NETBANKING from '../../assets/images/payment-methods/NETBANKING.png';
import DEBIT_CARD from '../../assets/images/payment-methods/DEBIT_CARD.png';

import VISA from '../../assets/images/payment-methods/VISA.png';
import MASTERCARD from '../../assets/images/payment-methods/MASTERCARD.png';
import JCB from '../../assets/images/payment-methods/JCB.png';
import AMERICAN_EXPRESS from '../../assets/images/payment-methods/AMERICAN_EXPRESS.png';
import DISCOVER from '../../assets/images/payment-methods/DISCOVER.png';
import DINERS_CLUB from '../../assets/images/payment-methods/DINERS_CLUB.png';
import G_PAY from '../../assets/images/payment-methods/G_PAY.png';
import EXPRESS_BANK_TRANSFER from '../../assets/images/payment-methods/EXPRESS_BANK_TRANSFER.png';

export default {
  BANKPAY,
  QUICKPAY,
  UNIONPAY_INTERNATIONAL,
  UNIONPAY_INTERNATIONAL_EURO,
  ALIPAY,
  UNIONPAY,
  CREDIT_CARD,
  COD,
  UPI,
  NETBANKING,
  DEBIT_CARD,

  VISA,
  MASTERCARD,
  AMERICAN_EXPRESS,
  JCB,
  DISCOVER,
  DINERS_CLUB,
  G_PAY,
  CUP: QUICKPAY,
  EXPRESS_BANK_TRANSFER,
  VIRTUAL_ACCOUNT_IDR: NETBANKING,
  QR_CODE_THB: NETBANKING
};