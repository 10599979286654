export const getConfig = async () => {
  const AuthTyp = await sessionStorage.getItem('cnyTokenUserAuthType');
  const AuthJWT = await sessionStorage.getItem('cnyTokenUserAuthJWT');
  const CountryJWT = await sessionStorage.getItem('cnyCountryUserAuthJWT');

  return {
    token: `${AuthTyp} ${AuthJWT}`,
    country: CountryJWT,
    apikey: 'REACT_APP_APIKEY',
    css: "REACT_APP_CSS"
  };
};
